// formType 1 文本框 2 下拉框
export const ixitFields = {
  0: [
    {
      label: '基本信息说明', field: 'baseInfo', formType: 1, desc: '说明本次信息收集的环境情况，设备情况，软件版本号等。',
    },
  ],
  1: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '身份认证机制及其相应授权过程的简要描述。此外，还要阐明该机制是用于用户认证还是机器对机器认证，以及它是否可以从网络接口直接访问。',
    },
    {
      label: '认证因素', field: 'authenticationFactor', formType: 1, desc: '用于认证的属性类型。对于密码而言，还需阐明密码是否由用户设置，并且是在已初始化状态下使用。例如：密码（由用户设置），密码（预装），生物指纹。',
    },
    {
      label: '密码生成机制', field: 'pwdGenMechanism', formType: 1, desc: '身份认证机制及其相应授权过程的简要描述。此外，还要阐明该机制是用于用户认证还是机器对机器认证，以及它是否可以从网络接口直接访问。',
    },
    {
      label: '加密细节', field: 'securityAssurance', formType: 1, desc: '对加密方法（协议、操作、原语、模式和密钥大小）的描述，用于保护考虑了密钥管理的更新机制，并促进所述的“安全保证”。',
    },
    {
      label: '预防暴力破解', field: 'bruteForcePrevention', formType: 1, desc: '如果身份认证机制可从网络接口直接访问，则需描述防止攻击者通过网络接口暴力破解其凭据的方法。示例：在一次不成功的登录后，延迟5秒钟后，才可以进行新的登录。',
    },

  ],
  // 用户文档
  2: [
    {
      label: '更改机制文档', formType: 2, fileName: 'changeFileName', filePath: 'changeDocPath', urlType: '', field: 'changeMechanismDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '替换文档', formType: 2, fileName: 'replaceFileName', filePath: 'replaceDocPath', urlType: '', field: 'replaceDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '传感器文档', formType: 2, fileName: 'sensorFileName', filePath: 'sensorDocPath', urlType: '', field: 'sensorDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '安全设置文档', formType: 2, fileName: 'securitySettingsFileName', filePath: 'securitySettingsDocPath', urlType: '', field: 'securitySettingsDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '配置检查文档', formType: 2, fileName: 'configCheckFileName', filePath: 'configCheckDocPath', urlType: '', field: 'configCheckDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '个人数据文档', formType: 2, fileName: 'personalDataFileName', filePath: 'personalDataDocPath', urlType: '', field: 'personalDataDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '遥测数据文档', formType: 2, fileName: 'telemetryDataFileName', filePath: 'telemetryDataDocPath', urlType: '', field: 'telemetryDataDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '删除（方法）文档', formType: 2, fileName: 'deleteFunctionFileName', filePath: 'deleteFunctionDocPath', urlType: '', field: 'deleteFunctionDoc', desc: '文档获取途径可能是制造商的网站和相应的URL、用户手册或内置帮助手册。',
    },
    {
      label: '型号名称', formType: 1, field: 'modelName', desc: '提示：API调用或DUT上的标签贴纸是告知用户型号名称的选项。',
    },
    {
      label: '支持期限', formType: 1, field: 'supportPeriod', desc: '例如安全更新的支持期限',
    },
    {
      label: '支持期限的公布', formType: 1, field: 'supportPeriodPublish', desc: '可能的公布方式是制造商的网站和相应的网址。',
    },
    {
      label: '漏洞披露政策的公布', formType: 1, field: 'disclosurePolicyPublish', desc: '可能的公布方式是制造商的网站和相应的网址。',
    },
    {
      label: '不可更新性的公布', formType: 1, field: 'cannotUpdatePublic', desc: '可能的公布方式是制造商的网站和相应的网址。',
    },
  ],
  3: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '简要描述与DUT相关的漏洞类型。例如：硬件、软件和固件都是可能的漏洞类型分类方式。如果单个流程就涵盖了所有漏洞，则没有必要进行分离处理。',
    },
    {
      label: '行动', field: 'action', formType: 1, desc: '描述在漏洞披露的情况下，对此类漏洞采取行动的方式，包括所有实体和责任。例如：在这种情况下，推出补丁和发布建议是可能的行动方式',
    },
    {
      label: '时间范围', field: 'timeRange', formType: 1, desc: '计划在出现漏洞时，采取给定的行动步骤的目标时间范围。例如：5天为初始响应时间范围，90天为补丁发布时间范围。',
    },
  ],
  4: [
    {
      label: '漏洞行动的确认', field: 'bugAction', formType: 1, desc: '确认相关漏洞项中描述的每项“行动”所需的基础设施已经到位，并向操作人员简要介绍，以便可以在目标“时间期限”内实现。',
    },
    {
      label: '漏洞监控的确认', field: 'bugMonitor', formType: 1, desc: '确认对于漏洞监控项中描述的每个漏洞监控、识别和纠正等操作所需的基础设施已到位，并且已向操作人员简要说明。',
    },
    {
      label: '更新程序的确认', field: 'updateProgram', formType: 1, desc: '确认更新过程项中描述的每一个更新过程所需的基础设施已经到位，并向操作人员简要介绍，以便可以在目标“时间期限”内实现。',
    },
    {
      label: '安全管理流程的确认', field: 'safetyManageProcess', formType: 1, desc: '确认安全管理流程项中描述的安全管理流程已经建立。',

    },
    {
      label: '安全开发流程的确认', field: 'securityDevProcess', formType: 1, desc: '确认（关键安全参数）安全管理流程项中描述的安全开发流程已经建立。',
    },
  ],
  // 漏洞监控
  5: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '对产品和服务中安全漏洞的监控、识别和纠正方式的描述。例如：识别漏洞的程序通常包括评估潜在漏洞是否与特定产品相关，负责人，收集信息的方法，以及在发现漏洞时要执行的工作流程。',
    },
  ],
  // 软件组成
  6: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '软件组件的简要描述。提示：BIOS、固件和引导加载程序可能是DUT的软件组件。',
    },
    {
      label: '更新机制', field: 'updateMechanism', formType: 1, desc: '引用IXIT 7-UpdMech中用于更新软件组件的更新机制。提示：“更新机制”的空列表表示“该软件组件的软件更新缺失”，在这种情况下需提供其理由。',
    },
    {
      label: '加密用法', field: 'encryptionUsage', formType: 1, desc: '指明软件组件是否使用密码算法或原语（是/否）；如果是，还包括制造商是否考虑了更新这些算法和原语的副作用（是/否）',
    },
  ],
  // 更新机制
  7: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '更新机制的简要描述，包括其主要特征。还需指出更新的传送是否基于网络。提示：根据复杂程度，将描述内容体现为“执行更新的步骤”可能是有用的。   示例：更新步骤：1）由用户发起，让DUT去询问服务器X，以验证更新是否可用；2）服务器将更新传送到DUT（基于网络）；3）DUT验证更新的真实性和完整性；4）在验证成功后，执行更新的安装。',
    },
    {
      label: '安全保证', field: 'securityAssurance', formType: 1, desc: '描述实现的安全目标和该机制防范的威胁。对于安全更新的真实性和完整性，还应注明安全保证是否由DUT自己提供。示例：该机制会由DUT自身，在安装更新之前验证更新的完整性和真实性。',
    },
    {
      label: '加密细节', field: 'encryptionDetails', formType: 1, desc: '对加密方法（协议、操作、原语、模式和密钥大小）的描述，用于保护考虑了密钥管理的更新机制，并促进所述的“安全保证”。示例：基于IETF RFC 3852的签名固件包，保证了软件更新的真实性和完整性。对于签名，使用具有RSA 2048和PSS填充的SHA-256算法。固件包的签名是通过制造商的私钥来实现。更新验证的公钥是在DUT的制造过程中集成的。',
    },
    {
      label: '启动和交互', field: 'startAndInteract', formType: 1, desc: '对如何启动更新的过程的简要描述，以及对启动和应用更新所必需的用户交互的简要描述。提示：该条目也用于指示它是否是自动更新机制。',
    },
    {
      label: '配置', field: 'config', formType: 1, desc: '简要说明用户如何配置软件更新的自动化和通知，以及用户可以选择哪些选项。此外，还应指出其默认配置。提示：启用、禁用和/或推迟自动更新，以及启用、禁用和/或推迟通知是可供选择的可能配置或选项。',
    },
    {
      label: '更新检查', field: 'updateMechanism', formType: 1, desc: '简要描述查询安全更新的机制和调度时间表。同时指明可用性检查是否由DUT自身执行。示例：向EXAMPLE.ORG查询最新稳定固件版本，并在初始化后及每天凌晨2点与已安装的版本进行比较（由DUT发起并执行）',
    },
    {
      label: '用户通知', field: 'userNotify', formType: 1, desc: '简要描述如何通知用户可用的更新和更新机制引起的中断（例如某些功能的有限可用性）。还会指出通知中包含哪些信息，以及通知是否由DUT本身实现。提示：通过用户界面的通知和推送消息是通知用户的可能方式',
    },
  ],
  // 更新过程
  8: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '部署安全更新的过程的简要描述，包括所有实体和责任。',
    },
    {
      label: '时间范围', field: 'timeRange', formType: 1, desc: '完成更新过程的目标时间范围。',
    },
  ],
  // 替换支持
  9: [
    {
      label: '隔离', field: 'isolation', formType: 1, desc: '离方法的说明，包括隔离DUT的步骤。',
    },
    {
      label: '替换', field: 'replace', formType: 1, desc: '硬件替换方法的说明，包括替换DUT硬件的步骤。',
    },
  ],
  // 安全参数
  10: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '安全参数的简要描述，包括其用途。此外，还额外阐明出于安全目的（硬编码标识），该参数是否是一个硬编码的每个设备唯一标识符，和/或在设备软件源代码中的硬编码。',
    },
    {
      label: '类型', field: 'type', formType: 1, desc: '指出安全参数是公共安全参数，还是关键安全参数。提示：公共和关键安全参数在《ETSI TS 103 645》/《ETSI EN 303 645》中有定义。',
    },
    {
      label: '安全保证', field: 'securityAssurance', formType: 1, desc: '描述实现的基线安全目标和在持久存储期间保护安全参数免受的威胁。',
    },
    {
      label: '保护方案', field: 'protectionPlan', formType: 1, desc: '描述用于实现安全保证的措施。这包括可以访问参数的主体和角色，包括与每个角色关联的特权。',
    },
    {
      label: '（参数）分配机制', field: 'allocationMechanism', formType: 1, desc: '如果“类型”指示参数是关键安全参数，则描述“为了DUT的运行，而分配参数值的机制”。提示：1、此类分配可能发生在初始化期间或已初始化状态（例如，当用户激活依赖于参数的设备功能时）；2、持久配置数据、运行时间配置数据、协议协商和默认值分配都是可能潜在的（参数）分配机制。',
    },
    {
      label: '通信机制', field: 'communicationMechanism', formType: 1, desc: '引用“IXIT 11-ComMech”中的通信机制，用于传递参数，和指示是否通过远程可访问接口进行通信。',
    },
    {
      label: '生成机制', field: 'generationMechanism', formType: 1, desc: '如果“类型”显示参数是关键安全参数，并且用于软件更新的完整性和真实性检查，或者用于保护与关联服务的通信，则描述生成参数值的机制，同时阐明该参数是用于软件更新的完整性和真实性检查，还是用于保护与关联服务的通信。示例：：参考一个标准的随机数生成程序XXX，及其应用设计文件。',
    },

  ],
  // 通信机制
  11: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '通信机制的简要描述，包括其目的和所用协议的描述。对于标准化的协议，一个引用就足够了。此外，阐明该机制是否可远程访问。',
    },
    {
      label: '安全保证', field: 'securityAssurance', formType: 1, desc: '描述实现的安全目标和该机制防范的威胁。提示：要考虑的最常见的安全保证包括对等体认证、来源认证、完整性保护、机密性保护和防重放。',
    },
    {
      label: '加密细节', field: 'encryptionDetails', formType: 1, desc: '描述加密方法（协议、操作、原语、模式和密钥大小），用于保护考虑密钥管理的通信机制，并促进所述的“安全保证”。示例：具有Security 2 Command Class v1的Z-Wave安全通讯协议。传输的数据经过AES-128 CCM认证和加密，以提高机密性和完整性。密钥交换基于带外机制。',
    },
    {
      label: '恢复措施', field: 'recoveryMeasures', formType: 1, desc: '描述确保连接建立有序进行的措施，包括实现稳定连接的预期、操作、稳定状态。提示：恢复措施考虑所用协议的顺序、基础设施的能力、协议的重置和初始化，以及大规模重新连接引起的问题。',
    },

  ],
  // 网络和安全实现
  12: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '简要描述实现的网络或安全功能，包括其目的和范围。提示：区分实现类型（如软件库或独立的微控制器）有助于确定所需评估或审查的相关功能。',
    },
    {
      label: '审查/评估方法', field: 'reviewAssessMethod', formType: 1, desc: '描述审查或评估的实现方法，包括它所基于的原则（例如，审计、同行审查、自动化代码分析）。此外，还需描述该方法所涵盖的实现范围。',
    },
    {
      label: '报告', field: 'report', formType: 1, desc: '审查或评价的结果，或能够证明“其实现情况已得到成功评价”的证书或评估报告的参考资料。提示：审查或评估的结果不必是一份单独的文件。例如，也可以使用软件管理工具中的bug跟踪文档来证明其实现已经通过评审。',
    },
  ],
  // 软件服务
  13: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '软件服务的简要描述，包括其目的。还需阐明服务是否可通过网络接口访问，以及在已初始化状态下是否是这种情况。默认情况下，不启动（禁用）的SSH守护进程就是这样一种服务，因为它仅用于开发目的。',
    },
    {
      label: '状态', field: 'status', formType: 1, desc: '指示服务在已初始化状态下是启用还是禁用。',
    },
    {
      label: '理由', field: 'reason', formType: 1, desc: '如果启用了该服务：说明该服务对于DUT的预期用途或运行是必需的。',
    },
    {
      label: '允许配置（是/否）', field: 'allowConfig', formType: 1, desc: '如果服务可通过网络接口访问，指明服务是否允许在服务配置过程中，更改与安全相关的配置；如果允许，简要说明可能的配置。',
    },
    {
      label: '认证机制', field: 'authenticationMechanism', formType: 1, desc: '如果服务可以通过网络接口访问，请参考“IXIT 1-AuthMech”中的认证机制，这些认证机制用于在使用服务之前进行认证。',
    },

  ],
  // 安全管理流程
  14: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '关于关键安全参数的整个生命周期的安全管理过程的简要描述。如果一个现有标准已经在使用，则提供相应标准的参考。提示：关键安全参数的生命周期通常考虑生成、分配、存储、更新、退役、归档、销毁、处理参数到期和泄漏的流程。',
    },

  ],
  // 接口
  15: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '接口的简要描述，包括其用途。对于物理接口，另外描述接口是“总是需要”、“从不需要”还是“仅在特定情况下需要（例如，间歇使用）”。指示接口是网络、物理（也包括空中接口）、逻辑接口，还是几种类型。',
    },
    {
      label: '类型', field: 'type', formType: 1, desc: '指示接口是网络、物理（也包括空中接口）、逻辑接口，还是几种类型。提示：《ETSI TS 103 645》/《ETSI EN 303 645》会区分网络接口和逻辑接口，但通常这两种接口是等同的。因此，在这种情况下，两种类型都要注明。',
    },
    {
      label: '状态', field: 'status', formType: 1, desc: '指示接口在已初始化状态下是启用还是禁用。对于启用的接口，给出正当理由。',
    },
    {
      label: '披露信息', field: 'disclosureInfo', formType: 1, desc: '如果接口是网络接口：描述在已初始化状态下，未经认证披露的信息以及披露的原因。另外还注明该信息是否是与安全相关的。提示：攻击者可以利用披露的信息来识别易受攻击的设备，例如软件版本。',
    },
    {
      label: '调试接口', field: 'debugInterface', formType: 1, desc: '如果接口是物理接口，注明该接口是否可以用作调试接口。',
    },
    {
      label: '保护', field: 'protect', formType: 1, desc: '如果接口是（从不需要暴露的）物理接口，描述限制接口暴露所需的保护方法。提示：1、对于调试接口，用于禁用接口的软件机制的描述是预期的（见测试集5.6-4）；2、对于非无线接口，给设备“加壳”是一种保护方法。',
    },
  ],
  // 代码最小化
  16: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '用于将代码最小化到必要功能的方法的简要描述。',
    },
  ],
  // 特权控制
  17: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '控制DUT上软件权限的机制的简要描述。',
    },
  ],
  // 访问控制
  18: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '硬件级的访问控制机制的简要描述。另外还要描述DUT的操作系统是如何支持其访问控制机制实现的。',
    },
  ],
  // 安全开发流程
  19: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '安全开发流程的简要描述。提示：如果一个现有标准已经在使用，则提供相应标准的参考。',
    },
  ],
  // 安全引导机制
  20: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '对用于DUT和受保护的软件部分的安全引导过程的机制（包括信任假设）的简要描述。',
    },
    {
      label: '安全保证', field: 'securityAssurance', formType: 1, desc: '对机制实现的安全目标的描述。示例：该机制实现了操作系统内核的真实性和完整性。',
    },
    {
      label: '检测机制', field: 'detectionMechanism', formType: 1, desc: '对“DUT软件中未经授权的更改”的检测机制的描述。',
    },
    {
      label: '用户通知', field: 'userNotify', formType: 1, desc: '简要说明如何通知用户“软件中的未经授权更改”。还会指出通知中包含哪些信息。提示：用户账户的电子邮件地址、用户设备（如智能手机、智能手表）的通信端点（如网络地址或链接地址）或状态LED都是通知用户的可能方式。',
    },
    {
      label: '通知功能', field: 'notifyFunction', formType: 1, desc: '通知用户所必需的网络功能的简要描述。SMTP协议（电子邮件通知的情况），RFCOMM协议细节（通过蓝牙通知的情况）。',
    },

  ],
  // 个人数据
  21: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: 'DUT处理的个人数据类型的简要描述。提示：个人数据的类型需要详细描述，以便对正在处理的数据类型有一个大致的了解。这包括对与众所周知的术语定义一致的个人数据的敏感程度的通用理解。示例：DUT使用的日志数据、支付信息、带时间戳的位置数据、音频输入流、生物特征识别数据。',
    },
    {
      label: '处理活动', field: 'processActivity', formType: 1, desc: '描述如何处理个人数据，包括所有相关方。此外还需描述处理的目的。提示：个人数据的永久存储，也作为备份，是一个处理活动。',
    },
    {
      label: '通信机制', field: 'communicationMechanism', formType: 1, desc: '参考“IXIT 11-ComMech”中用于传送个人数据的通信机制，并注明“通信的对方（Communication Partner）”是否为关联服务（是/否）。提示：空的“通信机制”列意味着个人数据没有被传输。',
    },
    {
      label: '敏感性（是/否）', field: 'sensitivity', formType: 1, desc: '根据《ETSI TS 103 645》 /《ETSI EN 303 645》条款5.8-2的定义，注明个人数据是否敏感。',
    },
    {
      label: '获得同意', field: 'getAgree', formType: 1, desc: '如果是在消费者同意的基础上处理个人数据，描述如何从消费者那里获得“处理同意”。',
    },
    {
      label: '撤回同意', field: 'revokeAgree', formType: 1, desc: '如果是在消费者同意的基础上处理个人数据，描述消费者如何撤回“对处理个人数据的同意”。',
    },

  ],
  // 外部传感器
  22: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '外部传感功能的简要描述。提示：这种外部传感功能可以是麦克风或摄像机等。',
    },

  ],
  // 恢复机制
  23: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '描述有助于DUT网络恢复和/或电源恢复的机制。示例：1、这种恢复机制可以是基于ext4的日志机制，在断电的情况下保护文件系统的完整性；2、这种恢复机制可以是一个小电池，备用电池能激活突发的设备中断，可以在断电时防止数据丢失。',
    },
    {
      label: '类型', field: 'type', formType: 1, desc: '注明恢复机制是解决网络连接中断或电源中断问题，还是同时解决这两个问题。',
    },
    {
      label: '安全保证', field: 'securityAssurance', formType: 1, desc: '描述实现的安全目标和该机制防范的威胁。示例：该机制在断电的情况下，保护DUT的数据完整性。',
    },

  ],
  // 遥测数据
  24: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '由DUT收集并提供给制造商的遥测数据的简要描述。',
    },
    {
      label: '目的', field: 'purpose', formType: 1, desc: '简要说明收集数据的目的。',
    },
    {
      label: '安全检测', field: 'securityDetection', formType: 1, desc: '如果数据用于安全检查，描述如何以及由谁（设备或关联服务）检查遥测数据的安全异常。提示：1、安全异常检测可在DUT外实现，即通过关联服务实现；2、设备遥测服务从DUT获取崩溃日志和使用数据（遥测数据），以便开发人员确定安全缺陷（安全异常检测）。',
    },
    {
      label: '个人数据', field: 'personalData', formType: 1, desc: '遥测数据中处理的个人数据，参考“IXIT 21-PersData”中的个人数据。',
    },

  ],
  // 删除功能
  25: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '用于删除用户数据的功能的简要描述。如果“目标类型”栏注明删除功能针对的是“关联服务”，则该功能覆盖的“关联服务”要被额外注明。提示：DUT的设置项可能提供从云服务器移除个人数据的功能。',
    },
    {
      label: '目标类型', field: 'type', formType: 1, desc: '注明删除功能针对的是设备上的用户数据，还是关联服务上的个人数据，或者同时针对两者。',
    },
    {
      label: '启动和交互', field: 'startAndInteract', formType: 1, desc: '用户交互的简要描述，这是启动和应用“删除功能”所必需的。',
    },
    {
      label: 'Confirmation', field: 'confirmation', formType: 1, desc: '简要描述在应用“删除功能”后，如何向用户给出“针对的数据已被删除”的指示。',
    },
  ],
  // 用户决策
  26: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '对用户在安装和维护流程中所做决策的描述。其在安装或维护流程中的位置也需要注明。',
    },
    {
      label: '选项', field: 'option', formType: 1, desc: '描述“用户可以选择的相关安全选项”，同时注明“缺省值”。',
    },
    {
      label: '触发方式', field: 'triggerMethod', formType: 1, desc: '简要描述“决策是如何触发的”，另外还要注明“该决策是否可以由用户触发”。',
    },
  ],
  // 用户界面
  27: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '允许用户输入数据的用户界面的简要描述。还要注明用户怎样才能访问该界面。',
    },
  ],
  // 外部API
  28: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '支持从DUT外部源输入数据的API的描述。提示：外部API通常用于机器间（machine-to-machine）的通信。',
    },
  ],
  // 数据输入校验
  29: [
    {
      label: 'ID', field: 'idCode', formType: 1, desc: '每个IXIT的唯一标识符，可使用顺序编号方式或标注方式赋值。',
    },
    {
      label: '描述', field: 'describe', formType: 1, desc: '对通过用户界面输入的数据，或通过API在服务和设备中的网络之间传输的数据进行校验的方法的描述，包括意外数据的处理。还应注明该方法针对的是哪些数据输入源。提示：为了校验数据输入，可以检查它是否是允许的类型（格式和结构）、允许的值、允许的基数或允许的排序。。',
    },
  ],
}

import { get, post, downloadByGet } from '@/libs/axios'

export const IxitPage = params => post('/ixit/manage/Page', params)

export const GetIxitOptions = () => get('/ixit/manage/GetOptions')
export const GetItxtDetailsList = id => get(`/ixit/manage/GetSelectManageList?id=${id}`)
export const GetSelectItemList = id => get(`/ixit/manage/GetSelectItemList?id=${id}`)
export const DelIxit = params => post('/ixit/manage/DeleteByIds', params)
export const DelIxitDetail = params => post('/ixit/itemDetails/Delete', params)
export const UpLoadAttachment = params => post('/ixit/item/UpLoadAttachment', params)
export const UpdateStatus = params => post('/ixit/item/UpdateStatus', params)
export const ItemUpdate = params => post('/ixit/item/Update', params)
export const IxitAudit = params => post('/ixit/item/Audit', params)
export const GetItemList = manageId => get(`/ixit/item/GetItemList?manageId=${manageId}`)
export const DownloadAttachment = id => downloadByGet(`/ixit/item/DownloadAttachment?id=${id}`)
export const IxitManageExport = id => downloadByGet(`/ixit/manage/Export?id=${id}`)
export const GetAttachment = id => get(`/ixit/item/attachment?id=${id}`)
export const DownloadDocFile = (itemId, docType) => downloadByGet(`/ixit/itemOther/DownloadUserDoc?itemId=${itemId}&docType=${docType}`)
export const GetItemDetailsList = id => get(`/ixit/itemDetails/GetItemDetailsList?itemId=${id}`)
export const GetManageById = id => get(`/ixit/manage/GetManageById?id=${id}`)
export const GetPlanDataOptions = () => get('/ixit/manage/GetPlanDataOptions')
export const GetItemDetails = (itemId, id) => get(`/ixit/itemDetails/GetItemDetails?itemId=${itemId}&id=${id}`)
export const GetItemOtherInfo = (itemId, itemType) => get(`/ixit/itemOther/GetItemOtherInfo?itemId=${itemId}&itemType=${itemType}`)
// 创建或更新第三方合作
export const saveOrUpdateIxIt = params => {
  const submitUrl = params.id ? '/ixit/manage/Update' : '/ixit/manage/Create'
  return post(submitUrl, params)
}
export const saveOrUpdateItemDetails = params => {
  const submitUrl = params.id ? '/ixit/itemDetails/Update' : '/ixit/itemDetails/Create'
  return post(submitUrl, params)
}
export const UpLoadUserDoc = params => post('/ixit/itemOther/UpLoadUserDoc', params)
export const saveOrUpdateItemOther = params => {
  const submitUrl = params.id ? '/ixit/itemOther/Update' : '/ixit/itemOther/Create'
  return post(submitUrl, params)
}
